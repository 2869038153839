import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from 'firebase/auth';
import { getDatabase, ref, set, onValue, push, get } from 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = initializeApp(config);

class Firebase {
  constructor() {
    this.auth = getAuth(app);
    this.db = getDatabase(app);
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = () => signOut(this.auth);

  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

    onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(this.auth, (authUser) => {
      if (authUser) {
        const userRef = ref(this.db, `users/${authUser.uid}`);
  
        onValue(userRef, (snapshot) => {
          const dbUser = snapshot.val();
  
          if (dbUser) {
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              roles: dbUser.roles || [], // Ensure roles is an array, even if it's not present in dbUser
              // Add other properties from dbUser as needed
            };
  
            next(authUser);
          } else {
            // If no user data is found in the database, provide a basic authUser object
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              roles: [], // or any default roles
            };
  
            next(authUser);
          }
        });
      } else {
        fallback();
      }
    });
  

    admissionApplications = () => ref(this.db, 'admissionApplications');

    // Use push directly on the reference
    pushToAdmissionApplications = (data) => push(this.admissionApplications(), data);
  
    // Example function similar to writeUserData
    writeUserData = (userId, name, email, imageUrl) => {
      set(ref(this.db, 'users/' + userId), {
        username: name,
        email: email,
        profile_picture: imageUrl,
      });
    };
  //     // Define the admissionApplications function
  // admissionApplications = () => ref(this.db,'admissionApplications');
  // user = (uid) => ref(this.db, `users/${uid}`);
  getAdmissionApplicationByUserId = async (userId) => {
    try {
      console.log("Fetching admission application data for user:", userId);
  
      const admissionApplicationsRef = ref(this.db, 'admissionApplications');
      const snapshot = await get(admissionApplicationsRef);
  
      console.log("Snapshot:", snapshot.val());
  
      if (snapshot.exists()) {
        // Iterate through entries to find the one with the matching userId
        const entries = snapshot.val();
        const userEntry = Object.values(entries).find(entry => entry.userId === userId);
  
        if (userEntry) {
          console.log("Found matching entry. Returning data:", userEntry);
          return userEntry;
        } else {
          console.log("No matching entry found. Throwing error.");
          throw new Error('Admission application not found for the user');
        }
      } else {
        console.log("Snapshot does not exist. Throwing error.");
        throw new Error('Admission applications data not found');
      }
    } catch (error) {
      console.error("Error fetching admission application data:", error.message);
      throw new Error(`Error fetching admission application data: ${error.message}`);
    }
  };
  
  //user = (uid) => ref(this.db, `users/${uid}`);
  user = (uid) => ref(this.db, `users/${uid}`);
  users = () => ref(this.db, `users`);

  onUsersValue = (callback) => onValue(this.users(), callback);

  offUsersValue = () => {}; // You can implement this if needed

  setUser = (uid, userData) => set(this.user(uid), userData);
}

export default Firebase;
