// Navigation.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'; // Import the hamburger icon from react-icons

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import styles from './navigation.module.css';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={styles.navigationContainer}>
      <div className={styles.logoContainer}>
        {/* Replace the following URL with the path to your logo image */}
        {/*<div><img src="../assets/enroll2.png" alt="Logo" className={styles.logoImage} /></div>*/}
        <div><span className={styles.companyName}>Enroll Uganda</span></div>
      </div>

      <ul className={`${styles.navigation} ${styles.navigationList}`}>
        <li className={styles.navigationListItem}>
          <Link to={ROUTES.LANDING} className={styles.navigationListItemAnchor}>Landing</Link>
        </li>
        <li className={styles.navigationListItem}>
          <Link to={ROUTES.APPLICATION} className={styles.navigationListItemAnchor}>Admission</Link>
        </li>
        <li className={styles.navigationListItem}>
          <Link to={ROUTES.HOME} className={styles.navigationListItemAnchor}>Home</Link>
        </li>
        <li className={styles.navigationListItem}>
          <Link to={ROUTES.ACCOUNT} className={styles.navigationListItemAnchor}>Account</Link>
        </li>
        {authUser.roles.includes(ROLES.ADMIN) && (
          <li className={styles.navigationListItem}>
            <Link to={ROUTES.ADMIN} className={styles.navigationListItemAnchor}>Admin</Link>
          </li>
        )}
        <li className={styles.navigationListItem}>
          <button onClick={toggleDropdown} className={styles.hamburgerButton}>
            <FaBars />
          </button>
        </li>
      </ul>

      {showDropdown && <DropdownMenu onClose={() => setShowDropdown(false)} />}
    </div>
  );
};

const DropdownMenu = ({ onClose }) => (
  <div className={styles.dropdownMenu}>
    <ul>
      <ul>
        <SignOutButton onClose={onClose} />
      </ul>
    </ul>
  </div>
);

const NavigationNonAuth = () => (
  <ul className={`${styles.navigation} ${styles.navigationList}`}>
    <li className={styles.navigationListItem}>
      <Link to={ROUTES.LANDING} className={styles.navigationListItemAnchor}>Landing</Link>
    </li>
    <li className={styles.navigationListItem}>
      <Link to={ROUTES.SIGN_IN} className={styles.navigationListItemAnchor}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
