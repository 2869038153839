// AdminPage.js
import React, { useEffect, useState } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { Component } from './Component';

const AdminPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = props.firebase.onUsersValue((snapshot) => {
      const usersObject = snapshot.val();

      if (usersObject) {
        const usersList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));

        setUsers(usersList);
      } else {
        setUsers([]); // Initialize as an empty array when usersObject is null
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
      props.firebase.offUsersValue();
    };
  }, [props.firebase]);

  return (
    <div style={{ margin: '40px' }}>
      <h1>Admin</h1>
      <p>The Admin Page is accessible by every signed-in admin user.</p>

      {loading && <div>Loading ...</div>}

      <Component users={users} />
    </div>
  );
};

// Modify the condition function to check for the ADMIN role
const condition = (authUser) => authUser && authUser.roles && authUser.roles.includes(ROLES.ADMIN);

export default withAuthorization(condition)(withFirebase(AdminPage));
