import React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';

// Styled components
const StyledCard = styled(Card)`
  max-width: 600px;
  margin: 0 auto;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  height: 80px;
  width: 80px;
  margin-bottom: 16px;
`;

const StyledTypography = styled(Typography)`
  && {
    margin-bottom: 8px;
  }
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: center;
`;

const AccountProfile = ({ user }) => (
  <StyledCard>
    <StyledCardContent>
      <Box>
        <StyledAvatar src={user.avatar || '/default-avatar.png'} />
        <StyledTypography gutterBottom variant="h5">
          {user.name || 'User Name'}
        </StyledTypography>
        <StyledTypography color="text.secondary" variant="body2">
          {user.city} {user.country}
        </StyledTypography>
        <StyledTypography color="text.secondary" variant="body2">
          {user.timezone}
        </StyledTypography>
      </Box>
    </StyledCardContent>
    <Divider />
    <StyledCardActions>
      <Button fullWidth variant="text">
        Upload picture
      </Button>
    </StyledCardActions>
  </StyledCard>
);

export { AccountProfile };