// SignUp.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import './SignUp.css'; // Import a CSS file for styling

const SignUpPage = () => (
  <div className="center">
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  isUser: false, // Add new state for the "Normal User" checkbox
  error: null,
};

const SignUpFormBase = ({ firebase }) => {
  const [formData, setFormData] = useState({ ...INITIAL_STATE });
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();
  
    const { username, email, passwordOne, isAdmin, isUser } = formData;
    const roles = [];
  
    if (isAdmin) {
      roles.push(ROLES.ADMIN);
    }
  
    if (isUser) {
      roles.push(ROLES.USER);
    }
  
    console.log('Firebase instance:', firebase); // Add this line
  
    firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Set user data in the Realtime Database
        firebase.setUser(authUser.user.uid, {
          username,
          email,
          roles,
        });
  
        setFormData({ ...INITIAL_STATE });
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        setFormData({ ...formData, error });
      });
  };
  

  const onChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeCheckbox = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const { username, email, passwordOne, passwordTwo, isAdmin, isUser, error } = formData;
  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '' ||
    email === '' ||
    username === '';

  return (
    <form onSubmit={onSubmit} className="sign-up-form">
      <h1>Sign Up</h1>
      <div>
        <label>Full Name</label>
        <input
          name="username"
          value={username}
          onChange={onChange}
          type="text"
          placeholder="Full Name"
        />
      </div>
      <div>
        <label>Email Address</label>
        <input
          name="email"
          value={email}
          onChange={onChange}
          type="text"
          placeholder="Email Address"
        />
      </div>
      <div>
        <label>Password</label>
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={onChange}
          type="password"
          placeholder="Password"
        />
      </div>
      <div>
        <label>Confirm Password</label>
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={onChange}
          type="password"
          placeholder="Confirm Password"
        />
      </div>
      <div>
        <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={onChangeCheckbox}
          />
        </label>
      </div>
      <div>
        <label>
          Normal User:
          <input
            name="isUser"
            type="checkbox"
            checked={isUser}
            onChange={onChangeCheckbox}
          />
        </label>
      </div>
      <div>
        <button disabled={isInvalid} type="submit">
          Sign Up
        </button>
      </div>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignUpLink = () => (
  <p>
    Don't Have An Account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withFirebase(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
