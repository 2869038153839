// SignIn.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { PasswordForgetLink } from '../PasswordForget';
import * as ROUTES from '../../constants/routes';
import './SignIn.css'; // Import a CSS file for styling

const SignInPage = () => (
  <div className="center">
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const SignInFormBase = ({ firebase }) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ ...INITIAL_STATE });

  const onSubmit = (event) => {
    const { email, password } = formState;

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setFormState({ ...INITIAL_STATE });
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        setFormState({ error });
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const { email, password, error } = formState;

  const isInvalid = password === '' || email === '';

  return (
    <form onSubmit={onSubmit} className="sign-in-form">
      <h1>Sign In</h1>
      <div>
        <label htmlFor="email">Please Enter Email</label>
        <input
          id="email"
          name="email"
          value={email}
          onChange={onChange}
          type="email"
          placeholder="Email Address"
        />
      </div>
      <div>
        <label htmlFor="password">Your Password</label>
        <input
          id="password"
          name="password"
          value={password}
          onChange={onChange}
          type="password"
          placeholder="Password"
        />
      </div>
      <button disabled={isInvalid} type="submit">
        Sign In
      </button>

      {error && <p>{error.message}</p>}

      <div className="additional-links">
        <PasswordForgetLink />
        <p>
          Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
        </p>
      </div>
    </form>
  );
};

const SignInForm = withFirebase(SignInFormBase);

export default SignInPage;

export { SignInForm };