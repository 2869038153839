import React, { useState } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import { AccountProfile } from './AccountProfile';

import styled from 'styled-components';

const AccountContainer = styled.div`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;

const CardContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 16px;
  padding: 20px;
`;

const Title = styled.h1`
  color: #007bff;
  font-size: 32px;
  margin-bottom: 16px;
`;

const UserInfo = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const UserInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const EditButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const EditableField = styled.input`
  width: 70%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const AccountPage = () => {
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingUsername, setEditingUsername] = useState(false);

  const handleEditEmail = () => setEditingEmail(!editingEmail);
  const handleEditUsername = () => setEditingUsername(!editingUsername);

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <AccountContainer>
          <Title>Account Information</Title>

          {/* Display the user profile using the AccountProfile component */}
          <AccountProfile user={authUser} />

          {/* Display the email, username, and ID */}
          <CardContainer>
            <UserInfo>
              <UserInfoItem>
                <span>Email:</span>
                {editingEmail ? (
                  <>
                    <EditableField type="text" defaultValue={authUser.email} />
                    <EditButton onClick={handleEditEmail}>Save</EditButton>
                  </>
                ) : (
                  <>
                    <span>{authUser.email}</span>
                    <EditButton onClick={handleEditEmail}>Edit</EditButton>
                  </>
                )}
              </UserInfoItem>

              <UserInfoItem>
                <span>Username:</span>
                {editingUsername ? (
                  <>
                    <EditableField
                      type="text"
                      defaultValue={authUser.username}
                    />
                    <EditButton onClick={handleEditUsername}>Save</EditButton>
                  </>
                ) : (
                  <>
                    <span>{authUser.username}</span>
                    <EditButton onClick={handleEditUsername}>Edit</EditButton>
                  </>
                )}
              </UserInfoItem>

              <UserInfoItem>
                <span>ID:</span>
                <span>{authUser.uid}</span>
              </UserInfoItem>
            </UserInfo>
          </CardContainer>

          {/* Allow the user to forget password */}
          {/*PasswordForgetForm */}

          {/* Allow the user to change password */}
          <CardContainer>
            <h2>Change Password</h2>
            <PasswordChangeForm />
          </CardContainer>
        </AccountContainer>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(AccountPage);