// Component.js
import React from 'react';
import {
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from '@mui/material';
import { FaSearch } from 'react-icons/fa';
// ... (your existing imports)
import { useNavigate, Link } from 'react-router-dom';

const Component = ({ users }) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.email && user.email.toLowerCase().includes(search.toLowerCase())) ||
      (user.username && user.username.toLowerCase().includes(search.toLowerCase()))
  );

  // Sample avatar URLs
  const sampleAvatars = [
    'https://picsum.photos/id/64/4326/2884',
    'https://picsum.photos/id/64/4326/2884',
    'https://picsum.photos/id/91/3504/2336',
    'https://picsum.photos/id/823/5000/3333',
  ];

  // Replace avatar values with sample URLs
  filteredUsers.forEach((user, index) => {
    user.avatar = sampleAvatars[index % sampleAvatars.length];
  });

  const COLUMNS = [
    { label: 'Avatar Image', accessor: 'avatar', isImage: true },
    { label: 'ID', accessor: 'uid' },
    { label: 'E-mail', accessor: 'email' },
    { label: 'Username', accessor: 'username' },
    { label: 'Form Details', accessor: 'uid', isLink: true },
    { label: 'Status', accessor: 'status' }, // Add the new "Status" column
  ];

  return (
    <div sx={{ marginX: '192px', paddingY: '192px' }}>
      <Stack spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
        <TextField label="Search User" value={search} icon={<FaSearch />} onChange={handleSearch} />
      </Stack>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 4,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell key={column.label} sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.uid}>
                {COLUMNS.map((column) => (
                  <TableCell key={column.accessor}>
                    {column.isLink && column.accessor === 'uid' ? (
                      <Link to={`/submitted-form-details/${user.uid}`} style={{ color: 'blue' }}>
                        View Form Details
                      </Link>
                    ) : column.isImage && column.accessor === 'avatar' ? (
                      <Avatar src={user[column.accessor]} alt={`Avatar-${user.uid}`} />
                    ) : (
                      // Render the status component
                      column.accessor === 'status' ? (
                        <StatusComponent status={user.status} />
                      ) : (
                        user[column.accessor]
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// Create a simple StatusComponent for rendering the status
const StatusComponent = ({ status }) => {
  return (
    <div style={{ textAlign: 'center', color: getStatusColor(status) }}>
      {status}
    </div>
  );
};

// Add a function to determine the color based on the status
const getStatusColor = (status) => {
  switch (status) {
    case 'Approved':
      return 'green';
    case 'Denied':
      return 'red';
    case 'Pending':
      return 'orange';
    default:
      return 'black';
  }
};

export { Component };
