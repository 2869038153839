// SignOutButton.js

import React from 'react';
import { withFirebase } from '../Firebase';
import './signoutButton.css'; // Import the CSS file

const SignOutButton = ({ firebase }) => (
  <button type="button" className="signOutButton" onClick={firebase.doSignOut}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
