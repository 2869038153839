// HomePage.js
import React from 'react';
import { withAuthorization } from '../Session';

const HomePage = () => (
  <div style={{ margin: '40px' }}> {/* Adjust the margin value as needed */}
    <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed-in user.</p>
  </div>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(HomePage);
