import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../Firebase";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
} from "@mui/material";

const SubmittedFormDetails = ({ userId }) => {
  const firebase = useContext(FirebaseContext);
  const [submittedData, setSubmittedData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userId) {
          console.error("Invalid userId");
          return;
        }

        // Fetch submitted form data
        const userSubmittedData = await firebase.getAdmissionApplicationByUserId(userId);
        setSubmittedData(userSubmittedData);

        // Fetch user data from Firebase Authentication
        const user = firebase.auth.currentUser;
        setUserData(user);

        // Initialize edit mode state
        const initialEditMode = {};
        Object.keys(userSubmittedData).forEach((key) => {
          initialEditMode[key] = false;
        });
        setEditMode(initialEditMode);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [firebase, userId]);

  const handleEditToggle = (field) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [field]: !prevEditMode[field],
    }));
  };

  const handleInputChange = (field, value) => {
    setSubmittedData((prevSubmittedData) => ({
      ...prevSubmittedData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    // Save the edited data to Firebase or your backend
    // You need to implement the save functionality according to your requirements
    // For simplicity, I am logging the edited data for now
    console.log("Edited Data:", submittedData);

    // After saving, reset edit mode
    setEditMode((prevEditMode) => {
      const resetEditMode = {};
      Object.keys(prevEditMode).forEach((key) => {
        resetEditMode[key] = false;
      });
      return resetEditMode;
    });
  };

  if (!submittedData || !userData) {
    return <p>Loading...</p>;
  }

  return (
    <Container maxWidth="md" style={{ marginTop: "20px", width: "210mm", height: "297mm" }}>
      <Card>
        <CardContent>
          <Typography variant="h5" color="primary" gutterBottom>
            Submitted Form Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Username:
              </Typography>
              {editMode.displayName ? (
                <TextField
                  variant="outlined"
                  fullWidth
                  value={submittedData.displayName}
                  onChange={(e) => handleInputChange("displayName", e.target.value)}
                />
              ) : (
                <Typography variant="body1">{userData.displayName}</Typography>
              )}
              <Button onClick={() => handleEditToggle("displayName")}>
                {editMode.displayName ? "Cancel" : "Edit"}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Email:
              </Typography>
              {editMode.email ? (
                <TextField
                  variant="outlined"
                  fullWidth
                  value={submittedData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              ) : (
                <Typography variant="body1">{userData.email}</Typography>
              )}
              <Button onClick={() => handleEditToggle("email")}>
                {editMode.email ? "Cancel" : "Edit"}
              </Button>
            </Grid>
            {Object.entries(submittedData)
              .filter(([key]) => key !== "username" && key !== "email")
              .map(([key, value]) => (
                <Grid item xs={12} md={6} key={key}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {key.replace(/([a-z])([A-Z])/g, "$1 $2")}:
                  </Typography>
                  {editMode[key] ? (
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={value}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  ) : (
                    <Typography variant="body1">{value}</Typography>
                  )}
                  <Button onClick={() => handleEditToggle(key)}>
                    {editMode[key] ? "Cancel" : "Edit"}
                  </Button>
                </Grid>
              ))}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SubmittedFormDetails;
