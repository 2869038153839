import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { AuthUserContext, withAuthorization } from "../Session";
import { useNavigate } from "react-router-dom"; 
import {
  TextField,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Input,
  FormHelperText,
  Typography,
} from "@mui/material";
import { FirebaseContext } from "../Firebase";
import SubmittedFormDetails from "./SubmittedFormDetails";
import SuccessPage from "./SuccessPage";

const condition = (authUser) =>
  authUser &&
  (authUser.roles.includes("ADMIN") || authUser.roles.includes("USER"));

const StudentForm = () => {
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  const [submittedData, setSubmittedData] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = authUser.uid;
        const userSubmittedData = await firebase.getAdmissionApplicationByUserId(userId);
        setSubmittedData(userSubmittedData);
      } catch (error) {
        console.error("Error fetching submitted data:", error.message);
      }
    };

    fetchData();
  }, [authUser, firebase]);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: "UG",
      studentClass: "Senior 1",
      subjects: "math",
    },
  });

  const onSubmit = async (data) => {
    try {
      const newApplicationRef = await firebase.pushToAdmissionApplications({
        userId: authUser.uid,
        ...data,
      });

      console.log("Form submitted successfully! Application key:", newApplicationRef.key);

     // Log the user UID before fetching data
     console.log("Fetching data for user:", authUser.uid);

     // Fetch submitted data after successful form submission
     const userId = authUser.uid;
     const submittedData = await firebase.getAdmissionApplicationByUserId(userId);

     console.log("Fetched data after form submission:", submittedData);
 
     setSubmittedData(submittedData);

      // Use navigate function to redirect to the success page
      navigate('/success');
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  }

  if (!condition(authUser)) {
    return <p>Unauthorized access</p>;
  }

  return (
    <Container maxWidth="sm">
      {submittedData ? (
        <SubmittedFormDetails userId={authUser.uid} />
      ) : (
        <>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography variant="h4" gutterBottom color="primary">
              Admission Application
        </Typography>
      </div>
      <Typography variant="h6" gutterBottom>
        User Details:
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* User Details Fields */}
          <Grid item xs={6}>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={authUser.email}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Username"
              fullWidth
              margin="normal"
              value={authUser.username}
              disabled
            />
          </Grid>

          {/* Other Form Fields */}
          <Grid item xs={6}>
            <TextField
              label="Former School"
              fullWidth
              margin="normal"
              {...register("formerSchool", {
                required: "Former School is required",
              })}
              defaultValue={authUser.formerSchool || ""}
              error={!!errors.formerSchool}
              helperText={errors.formerSchool?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Age"
              type="number"
              fullWidth
              margin="normal"
              {...register("age", {
                required: "Age is required",
                min: { value: 1, message: "Age must be at least 1" },
              })}
              error={!!errors.age}
              helperText={errors.age?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="class-label">Class</InputLabel>
              <Controller
                name="studentClass"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="class-label"
                    {...field}
                    error={!!errors.studentClass}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Class
                    </MenuItem>
                    <MenuItem value="Senior 1">Senior 1</MenuItem>
                    <MenuItem value="Senior 2">Senior 2</MenuItem>
                    <MenuItem value="Senior 3">Senior 3</MenuItem>
                    <MenuItem value="Senior 4">Senior 4</MenuItem>
                    <MenuItem value="Senior 5">Senior 5</MenuItem>
                    <MenuItem value="Senior 6">Senior 6</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={!!errors.studentClass}>
                {errors.studentClass?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="UNEB Results"
              fullWidth
              margin="normal"
              {...register("unebResults", {
                required: "UNEB Results is required",
              })}
              defaultValue={
                authUser.unebResults !== undefined ? authUser.unebResults : ""
              }
              error={!!errors.unebResults}
              helperText={errors.unebResults?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="country-label">Country of Origin</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="country-label"
                    {...field}
                    error={!!errors.country}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Country
                    </MenuItem>
                    {/* Add more countries as needed */}
                    <MenuItem value="UG">Uganda</MenuItem>
                    <MenuItem value="KE">Kenya</MenuItem>
                    <MenuItem value="RWD">Rwanda</MenuItem>
                    <MenuItem value="SS">South Sudan</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={!!errors.country}>
                {errors.country?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Date of Birth"
              type="date"
              fullWidth
              margin="normal"
              {...register("dob", { required: "Date of Birth is required" })}
              defaultValue={authUser.dob !== undefined ? authUser.dob : ""}
              error={!!errors.dob}
              helperText={errors.dob?.message}
            />
          </Grid>

          {/* Second Column */}
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="subjects-label">Subjects</InputLabel>
              <Controller
                name="subjects"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="subjects-label"
                    {...field}
                    error={!!errors.subjects}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Subjects
                    </MenuItem>
                    <MenuItem value="math">Math</MenuItem>
                    <MenuItem value="science">Science</MenuItem>
                    {/* Add more subjects as needed */}
                  </Select>
                )}
              />
              <FormHelperText error={!!errors.subjects}>
                {errors.subjects?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Guardian/Parent Names"
              fullWidth
              margin="normal"
              {...register("guardianNames", {
                required: "Parent/Guardian Names are required",
              })}
              defaultValue={
                authUser.guardianNames !== undefined
                  ? authUser.guardianNames
                  : ""
              }
              error={!!errors.guardianNames}
              helperText={errors.guardianNames?.message}
            />
          </Grid>
          {/* Bio field */}
          <Grid item xs={12}>
            <TextField
              label="Bio"
              multiline
              fullWidth
              rows={4}
              margin="normal"
              {...register("bio")}
              defaultValue={authUser.bio !== undefined ? authUser.bio : ""}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="results-slip-label">
                Upload Results Slip
              </InputLabel>
              <Input type="file" {...register("resultsSlip")} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="recommendation-letter-label">
                Upload Recommendation Letter
              </InputLabel>
              <Input type="file" {...register("recommendationLetter")} />
            </FormControl>
          </Grid>
        </Grid>
        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      </>
      )}
    </Container>
  );
};

export default withAuthorization(condition)(StudentForm);