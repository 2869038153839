import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Container } from "@mui/material";
import SubmittedFormDetails from "./SubmittedFormDetails";

const SuccessPage = ({ submittedData, authUser }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (submittedData) {
      console.log("Received data in SuccessPage:", submittedData);
      setLoading(false);
    }
  }, [submittedData]);

  return (
    <Container maxWidth="sm" style={{ padding: "20px", margin: "20px auto" }}>
      <Card>
        <CardContent>
          <Typography variant="h5" color="primary">
            Form submitted successfully! We will notify you once we are done with verification.
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Submitted Data:
          </Typography>
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <CardContent>
              <Typography variant="body1">{`Age: ${submittedData.age}`}</Typography>
              <Typography variant="body1">{`Bio: ${submittedData.bio}`}</Typography>
              {/* Render the SubmittedFormDetails component here */}
              <SubmittedFormDetails userId={authUser.uid} />
              {/* Add more fields as needed */}
            </CardContent>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default SuccessPage;
