import React from 'react';
import { withFirebase } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import AuthUserContext from './context';
import * as ROUTES from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      this.listener = null;
    }

    componentDidMount() {
      const { navigate } = this.props;
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!authUser || !condition(authUser)) {
            navigate(ROUTES.ADMIN);
          }
        },
        () => {
          navigate(ROUTES.SIGN_IN);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (authUser && condition(authUser) ? <Component {...this.props} /> : null)}
        </AuthUserContext.Consumer>
      );
    }
  }

  const WithFirebaseAuthorization = withFirebase(WithAuthorization);

  return (props) => {
    const navigate = useNavigate();
    return <WithFirebaseAuthorization {...props} navigate={navigate} />;
  };
};

export default withAuthorization;
