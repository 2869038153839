import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material'; // Import Button from Material-UI
import './landing.css';

const Landing = () => (
  <div className="landingContainer">
    <div className="landingContent">
      <h1 className="landingTitle">Enroll App</h1>
      <p className="landingSubtitle">Experience a Seamless remote borderless Admission!</p>

      <div className="cardContainer">
        <div className="card primaryCard">
          <h2>Primary</h2>
          <p>This is our primary service. Discover the best opportunities for your primary education journey.</p>
        </div>

        <div className="card secondaryCard">
          <h2>Secondary</h2>
          <p>Explore our secondary school Admission tailored to meet your unique requirements and goals.</p>
        </div>

        <div className="card universityCard">
          <h2>University</h2>
          <p>Embark on a higher education journey with our university-level admission.</p>
        </div>
      </div>

      {/* Apply Button */}
      <Link to="/application">
        <Button variant="contained" color="primary">
          Apply
        </Button>
      </Link>
    </div>

    <div className="copyright">
      <p>
        <a href="https://avodahsystems.com" target="_blank" rel="noopener noreferrer">
          &copy; {new Date().getFullYear()} Avodah Systems
        </a>
      </p>
    </div>
  </div>
);

export default Landing;